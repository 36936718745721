import { useStore } from '../../store/useStore';
import { STRIPE_CONFIG } from './config';
import type { CheckoutResult } from './config';

export const initiateCheckout = async (email?: string): Promise<CheckoutResult> => {
  const store = useStore.getState();

  try {
    if (!email) {
      throw new Error('Email is required for checkout');
    }

    // Save upgrade intent in store
    await store.setUpgradeIntent('pro');

    store.addNotification({
      title: 'Starting Checkout',
      message: 'Redirecting to secure payment page...',
      type: 'info'
    });

    // Create Stripe checkout session
    const response = await fetch('/api/create-checkout-session', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        priceId: STRIPE_CONFIG.priceId,
        successUrl: STRIPE_CONFIG.successUrl,
        cancelUrl: STRIPE_CONFIG.cancelUrl
      })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.error?.message || 'Failed to create checkout session');
    }

    const { sessionId } = await response.json();

    // Redirect to Stripe Checkout
    window.location.href = `https://checkout.stripe.com/pay/${sessionId}`;

    return { success: true };
  } catch (err) {
    const error = {
      type: 'checkout_error',
      message: err instanceof Error ? err.message : 'Failed to start checkout process'
    };

    store.addNotification({
      title: 'Checkout Error',
      message: error.message,
      type: 'error'
    });

    return { success: false, error };
  }
};

export const handlePaymentSuccess = async () => {
  const store = useStore.getState();
  const { userData } = store;

  if (!userData) {
    throw new Error('No user data found when handling successful payment');
  }

  try {
    // Poll for profile update
    let attempts = 0;
    const maxAttempts = 10;
    const pollInterval = 2000; // 2 seconds

    const checkUpgrade = async () => {
      const { data: profile } = await store.refreshUserProfile();
      
      if (profile?.planId === 'pro') {
        // Update local state
        await store.updateUserPlan('pro');
        await store.clearUpgradeIntent();
        
        store.addNotification({
          title: 'Welcome to Pro!',
          message: 'Your account has been upgraded successfully. You now have access to unlimited data sources.',
          type: 'success'
        });
        
        return true;
      }
      
      return false;
    };

    // First immediate check
    if (await checkUpgrade()) {
      return;
    }

    // Start polling if not upgraded yet
    while (attempts < maxAttempts) {
      await new Promise(resolve => setTimeout(resolve, pollInterval));
      attempts++;

      if (await checkUpgrade()) {
        return;
      }
    }

    throw new Error('Upgrade status check timed out. Please contact support if the issue persists.');
  } catch (err) {
    console.error('Error updating plan after payment:', err);
    store.addNotification({
      title: 'Plan Upgrade Error',
      message: 'There was an issue verifying your upgrade. Please contact support.',
      type: 'error'
    });
    throw err;
  }
};