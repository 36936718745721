import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { supabase } from './lib/supabase';
import { AuthProvider } from './components/AuthProvider';
import { useStore } from './store/useStore';
import Layout from './components/Layout';
import ProtectedRoute from './components/auth/ProtectedRoute';

// Pages
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import GetStartedPage from './pages/GetStartedPage';
import ContactSalesPage from './pages/ContactSalesPage';
import CheckoutPage from './pages/CheckoutPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import PricingPage from './pages/PricingPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import DataSourcesPage from './pages/dashboard/DataSourcesPage';
import AnalyticsPage from './pages/dashboard/AnalyticsPage';
import AiChatPage from './pages/dashboard/AiChatPage';
import StockMarketPage from './pages/dashboard/StockMarketPage';
import TeamPage from './pages/dashboard/TeamPage';
import SettingsPage from './pages/dashboard/SettingsPage';
import PreviewPage from './pages/PreviewPage';

export default function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useStore();

  useEffect(() => {
    // Only redirect to dashboard if authenticated and on auth routes
    if (isAuthenticated && ['/login', '/signup'].includes(location.pathname)) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, location.pathname, navigate]);

  return (
    <SessionContextProvider supabaseClient={supabase}>
      <AuthProvider>
        <Layout>
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<HomePage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/get-started" element={<GetStartedPage />} />
            <Route path="/contact-sales" element={<ContactSalesPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/payment-success" element={<PaymentSuccessPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            
            {/* Protected Routes */}
            <Route path="/dashboard" element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            } />
            <Route path="/dashboard/data-sources" element={
              <ProtectedRoute>
                <DataSourcesPage />
              </ProtectedRoute>
            } />
            <Route path="/preview" element={
              <ProtectedRoute>
                <PreviewPage />
              </ProtectedRoute>
            } />
            <Route path="/dashboard/analytics" element={
              <ProtectedRoute>
                <AnalyticsPage />
              </ProtectedRoute>
            } />
            <Route path="/dashboard/ai-chat" element={
              <ProtectedRoute>
                <AiChatPage />
              </ProtectedRoute>
            } />
            <Route path="/dashboard/stock-market" element={
              <ProtectedRoute>
                <StockMarketPage />
              </ProtectedRoute>
            } />
            <Route path="/dashboard/team" element={
              <ProtectedRoute>
                <TeamPage />
              </ProtectedRoute>
            } />
            <Route path="/dashboard/settings" element={
              <ProtectedRoute>
                <SettingsPage />
              </ProtectedRoute>
            } />
          </Routes>
        </Layout>
      </AuthProvider>
    </SessionContextProvider>
  );
}