import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from '../lib/supabase';
import { generateUUID } from '../lib/utils/uuid';

interface UserData {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  organizationName: string;
  organizationSize: string;
  planId: 'basic' | 'pro';
}

interface SetupData {
  dataSources: string[];
  dashboards: string[];
  aiPreferences: string[];
}

interface Notification {
  id: string;
  title: string;
  message: string;
  type: 'success' | 'error' | 'warning' | 'info';
  read: boolean;
  timestamp: string;
}

interface State {
  isAuthenticated: boolean;
  userData: UserData | null;
  setupData: SetupData | null;
  connectedDataSources: any[];
  notifications: Notification[];
  theme: 'light' | 'dark';
  upgradeIntent: string | null;
  setUserData: (data: UserData | null) => void;
  setSetupData: (data: SetupData) => void;
  updateUserData: (updates: Partial<Omit<UserData, 'id' | 'email' | 'planId'>>) => Promise<void>;
  updateUserPlan: (planId: 'basic' | 'pro') => Promise<void>;
  addDataSource: (source: any) => void;
  removeDataSource: (id: string) => void;
  canAddDataSource: () => boolean;
  setTheme: (theme: 'light' | 'dark') => void;
  addNotification: (notification: Omit<Notification, 'id' | 'read' | 'timestamp'>) => void;
  markNotificationAsRead: (id: string) => void;
  markAllNotificationsAsRead: () => void;
  clearNotifications: () => void;
  logout: () => Promise<void>;
  setUpgradeIntent: (plan: 'basic' | 'pro') => Promise<void>;
  clearUpgradeIntent: () => Promise<void>;
  refreshUserProfile: () => Promise<{ data: UserData | null; error?: Error }>;
}

export const useStore = create<State>()(
  persist(
    (set, get) => ({
      isAuthenticated: false,
      userData: null,
      setupData: null,
      connectedDataSources: [],
      notifications: [],
      theme: 'light',
      upgradeIntent: null,

      setUserData: (data) => {
        set({ 
          userData: data,
          isAuthenticated: !!data
        });

        if (data) {
          get().addNotification({
            title: 'Login Successful',
            message: `Welcome back, ${data.firstName}! You logged in at ${new Date().toLocaleString()}`,
            type: 'success'
          });
        }
      },

      setSetupData: (data) => {
        set({ setupData: data });
      },

      updateUserData: async (updates) => {
        const { userData } = get();
        if (!userData) throw new Error('No user logged in');

        try {
          const { error } = await supabase
            .from('profiles')
            .update({
              first_name: updates.firstName,
              last_name: updates.lastName,
              organization_name: updates.organizationName,
              organization_size: updates.organizationSize
            })
            .eq('id', userData.id);

          if (error) throw error;

          const updatedData = {
            ...userData,
            ...updates
          };

          set({ userData: updatedData });

          get().addNotification({
            title: 'Profile Updated',
            message: 'Your profile information has been updated successfully.',
            type: 'success'
          });
        } catch (err) {
          console.error('Error updating user data:', err);
          throw new Error('Failed to update profile');
        }
      },

      updateUserPlan: async (planId) => {
        const { userData } = get();
        if (!userData) throw new Error('No user logged in');

        try {
          const { error } = await supabase
            .from('profiles')
            .update({ plan_id: planId })
            .eq('id', userData.id);

          if (error) throw error;

          set({
            userData: {
              ...userData,
              planId
            }
          });

          get().addNotification({
            title: 'Plan Updated',
            message: planId === 'pro' 
              ? 'Your account has been upgraded to Pro! You now have access to unlimited data sources.'
              : 'Your plan has been updated successfully.',
            type: 'success'
          });
        } catch (err) {
          console.error('Error updating user plan:', err);
          throw new Error('Failed to update plan');
        }
      },

      addDataSource: (source) => {
        const { userData, connectedDataSources, canAddDataSource } = get();
        
        if (!canAddDataSource()) {
          if (userData?.planId === 'basic') {
            throw new Error('Basic plan users can only have one data source. Please upgrade to Pro for unlimited sources.');
          } else {
            throw new Error('Unable to add data source. Please try again.');
          }
        }

        const newSource = {
          ...source,
          id: source.id || generateUUID(),
          createdAt: new Date().toISOString()
        };

        set({
          connectedDataSources: [...connectedDataSources, newSource]
        });

        get().addNotification({
          title: 'Data Source Added',
          message: `New data source "${source.name}" has been connected successfully.`,
          type: 'success'
        });
      },

      removeDataSource: (id) => {
        const source = get().connectedDataSources.find(s => s.id === id);
        set((state) => ({
          connectedDataSources: state.connectedDataSources.filter(s => s.id !== id)
        }));

        if (source) {
          get().addNotification({
            title: 'Data Source Removed',
            message: `Data source "${source.name}" has been removed.`,
            type: 'info'
          });
        }
      },

      canAddDataSource: () => {
        const { userData, connectedDataSources } = get();
        if (!userData) return false;
        if (userData.planId === 'pro') return true;
        return connectedDataSources.length === 0;
      },

      setTheme: (theme) => {
        set({ theme });
        document.documentElement.classList.remove('light', 'dark');
        document.documentElement.classList.add(theme);
      },

      addNotification: (notification) => {
        const newNotification: Notification = {
          id: generateUUID(),
          ...notification,
          read: false,
          timestamp: new Date().toISOString()
        };

        set((state) => ({
          notifications: [newNotification, ...state.notifications]
        }));
      },

      markNotificationAsRead: (id) => {
        set((state) => ({
          notifications: state.notifications.map(n =>
            n.id === id ? { ...n, read: true } : n
          )
        }));
      },

      markAllNotificationsAsRead: () => {
        set((state) => ({
          notifications: state.notifications.map(n => ({ ...n, read: true }))
        }));
      },

      clearNotifications: () => {
        set({ notifications: [] });
      },

      logout: async () => {
        await supabase.auth.signOut();
        set({
          isAuthenticated: false,
          userData: null,
          setupData: null,
          connectedDataSources: [],
          notifications: []
        });
      },

      setUpgradeIntent: async (plan) => {
        const intentId = generateUUID();
        try {
          const { error } = await supabase
            .from('profiles')
            .update({ upgrade_intent: intentId })
            .eq('id', get().userData?.id);

          if (error) throw error;
          set({ upgradeIntent: intentId });
        } catch (err) {
          console.error('Error saving upgrade intent:', err);
          throw new Error('Failed to initiate upgrade');
        }
      },

      clearUpgradeIntent: async () => {
        try {
          const { error } = await supabase
            .from('profiles')
            .update({ upgrade_intent: null })
            .eq('id', get().userData?.id);

          if (error) throw error;
          set({ upgradeIntent: null });
        } catch (err) {
          console.error('Error clearing upgrade intent:', err);
        }
      },

      refreshUserProfile: async () => {
        try {
          const { data: profile, error } = await supabase
            .from('profiles')
            .select('*')
            .eq('id', get().userData?.id)
            .single();

          if (error) throw error;

          if (profile) {
            const userData = {
              id: profile.id,
              email: profile.email,
              firstName: profile.first_name,
              lastName: profile.last_name,
              organizationName: profile.organization_name,
              organizationSize: profile.organization_size,
              planId: profile.plan_id,
              paymentStatus: profile.payment_status
            };

            set({ userData });
            return { data: userData };
          }

          return { data: null };
        } catch (err) {
          console.error('Error refreshing user profile:', err);
          return { data: null, error: err instanceof Error ? err : new Error('Failed to refresh profile') };
        }
      }
    }),
    {
      name: 'algozi-storage',
      partialize: (state) => ({
        connectedDataSources: state.connectedDataSources,
        notifications: state.notifications,
        theme: state.theme,
        upgradeIntent: state.upgradeIntent
      })
    }
  )
);