import React from 'react';
import { useLocation, Link, Navigate } from 'react-router-dom';
import { ArrowLeft, AlertCircle } from 'lucide-react';
import Chart from '../components/charts/Chart';
import { exportChartAsImage } from '../lib/utils/chartExport';
import type { ChartData } from '../types/charts';

export default function PreviewPage() {
  const location = useLocation();
  
  // Redirect if no state is present
  if (!location.state?.charts) {
    return <Navigate to="/dashboard/data-sources" replace />;
  }

  const charts: ChartData[] = location.state.charts;
  const sourceName = location.state.sourceName;

  const handleDownload = async (chartIndex: number) => {
    try {
      await exportChartAsImage(
        `preview-chart-${chartIndex}`,
        charts[chartIndex].title
      );
    } catch (error) {
      console.error('Error downloading chart:', error);
    }
  };

  if (charts.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-8">
        <div className="max-w-7xl mx-auto">
          <Link
            to="/dashboard/data-sources"
            className="inline-flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 mb-8"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back to Data Sources
          </Link>

          <div className="bg-yellow-50 dark:bg-yellow-900/50 p-4 rounded-lg flex items-center text-yellow-700 dark:text-yellow-200">
            <AlertCircle className="h-5 w-5 mr-2" />
            <p>No charts could be generated from this data source. Please check the data format and try again.</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-8">
      <div className="max-w-7xl mx-auto">
        <Link
          to="/dashboard/data-sources"
          className="inline-flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Data Sources
        </Link>

        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
            Data Visualization
          </h1>
          {sourceName && (
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Showing charts for {sourceName}
            </p>
          )}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {charts.map((chart, index) => (
            <Chart
              key={index}
              chart={chart}
              index={index}
              onDownload={handleDownload}
            />
          ))}
        </div>
      </div>
    </div>
  );
}